<template>
    <div class="main-container">
      <h5 class="pageTitle">드론 타입 등록</h5>
  
      <div class="form-container">
        <!-- 🏷 모델 -->
        <div class="form-group">
          <label for="model">모델(타입)(최대 10byte)</label>
          <input 
          v-model="form.model" 
          type="text" 
          class="input-box" 
          placeholder="모델(타입)명을 입력하세요"
          @input="handleModelInput"
          />
          <div style="text-align: right; font-size: 12px; color: gray;">
            ({{ modelByteLength }}/10 byte)
          </div>
        </div>
  
        <!-- 🏷 타입 -->
        <!-- <div class="form-group">
          <label for="type">타입</label>
          <input v-model="form.type" type="text" class="input-box" placeholder="타입을 입력하세요" />
        </div> -->
  
        <!-- 🏷 타입 이름 -->
        <!-- <div class="form-group">
          <label for="name">타입 이름</label>
          <input v-model="form.name" type="text" class="input-box" placeholder="타입 이름을 입력하세요" />
        </div> -->
  
        <!-- 🏷 순서 -->
        <div class="form-group">
          <label for="order">순서 (최대: {{ maxOrder }})</label>
          <input v-model.number="form.order" type="number" class="input-box" placeholder="순서를 입력하세요" />
          
        </div>
       
  
        <!-- 버튼 -->
        <div class="button-container">
          <button class="button-list" @click="goToList">목록</button>
          <button class="button-register" @click="registerDroneType">등록</button>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import axios from "@/axios";
  import Swal from "sweetalert2";
  
  export default {
    name: "DroneTypeCreate",
    data() {
      return {
        form: {
          model: "",
          type: "",
          name: "",
          order: "",
        },
        maxOrder: 0, // 현재 가장 높은 order 값
      };
    },
  
    async mounted() {
      await this.fetchMaxOrder(); //현재 가장 높은 order 값 가져오기
    },
   computed:{

    modelByteLength() {
      return [...this.form.model].reduce((acc, char) => {
        return acc + (/[가-힣]/.test(char) ? 2 : 1);
      }, 0);
    }
   },
    methods: {
      handleModelInput() {
        const input = this.form.model;
        let result = '';
        let byteLength = 0;

        for (const char of input) {
          const charByte = /[가-힣]/.test(char) ? 2 : 1;
          if (byteLength + charByte > 10) break;
          byteLength += charByte;
          result += char;
        }

        this.form.model = result;
      },
      //  현재 가장 높은 order 값 가져오기
      async fetchMaxOrder() {
        try {
          const token = this.getToken();
          const response = await axios.get("/master/drone-type_maxOrder", {
            headers: { Authorization: `Bearer ${token}` },
          });
          this.maxOrder = response.data.maxOrder || 0;
        } catch (error) {
          console.error("최대 order 조회 실패:", error);
        }
      },
  
      //  드론 타입 등록 함수
      async registerDroneType() {
        if (!this.validateForm()) return; //  필드 유효성 검사
  
        try {
          const token = this.getToken();
          const formData = {
            model: this.form.model,
            type: this.form.type,
            name: this.form.model,
            // name: this.form.name,
            order: this.form.order.toString(),
          };
  
          const response = await axios.post("/master/drone-type", formData, {
            headers: { Authorization: `Bearer ${token}` },
          });
  
          if (response.status === 201) {
            Swal.fire("성공", "드론 타입이 성공적으로 등록되었습니다.", "success");
            this.resetForm();
            this.goToList();
          }
        } catch (error) {
          Swal.fire("오류", "등록 중 문제가 발생했습니다.", "error");
          console.error("드론 타입 등록 실패:", error);
        }
      },
  
      // 필드 유효성 검사
      validateForm() {
        if (!this.form.model.trim() || !this.form.order) {
          Swal.fire("오류", "모든 필드를 입력하세요.", "warning");
          return false;
        }
  
        if (!this.form.order || this.form.order <= this.maxOrder) {
          Swal.fire("오류", `순서는 현재 최고값 (${this.maxOrder})보다 커야 합니다.`, "warning");
          return false;
        }
  
        return true;
      },
  
      // 토큰 가져오기
      getToken() {
        const tokenData = JSON.parse(sessionStorage.getItem("token"));
        return tokenData ? tokenData.access_token : "";
      },
  
      // 입력 필드 초기화
      resetForm() {
        this.form = {
          model: "",
          type: "",
          name: "",
          order: "",
        };
        this.fetchMaxOrder(); //  등록 후 최신 max order 갱신
      },
  
      //  목록 페이지 이동
      goToList() {
        this.$router.push("/master-droneTypeRead");
      },
    },
  };
  </script>
  
  <style scoped>
  /* 기존 스타일 유지 */
  .main-container {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .pageTitle {
    font-weight: bold;
    margin-bottom: 40px;
    text-align: left;
    width: 100%;
  }
  
  .form-container {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 70%;
    max-width: 800px;
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }
  
  .input-box, .select-box {
    flex: 1;
    padding: 8px;
    margin-right: 40px;
    border: 1px solid rgba(130, 128, 128, 0.26);
    border-radius: 3px;
    max-width: 300px;
  }
  .input-select {
  width: 200px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}
  .checkbox {
    margin-top: 10px;
  }
  
  .button-container {
    display: flex;
    gap: 15px;
    justify-content: flex-start;
  }
  
  .button-list {
    background-color: #ff9800;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 3px;
    cursor: pointer;
  }
  
  .button-register {
    background-color: #4caf50;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    
  }
  </style>
  